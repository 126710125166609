exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-faqs-index-js": () => import("./../../../src/pages/faqs/index.js" /* webpackChunkName: "component---src-pages-faqs-index-js" */),
  "component---src-pages-home-services-index-js": () => import("./../../../src/pages/home-services/index.js" /* webpackChunkName: "component---src-pages-home-services-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-old-js": () => import("./../../../src/pages/index_old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-new-terms-index-js": () => import("./../../../src/pages/new-terms/index.js" /* webpackChunkName: "component---src-pages-new-terms-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-australia-108-wumbo-charged-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/codebuild/output/src4072705058/src/github.com/WumboTech/wewumbo.io_v4/news/australia-108-wumbo-charged/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-australia-108-wumbo-charged-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-christmas-at-melbourne-square-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/codebuild/output/src4072705058/src/github.com/WumboTech/wewumbo.io_v4/news/christmas-at-melbourne-square/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-christmas-at-melbourne-square-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-convenience-is-the-new-luxury-with-home-services-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/codebuild/output/src4072705058/src/github.com/WumboTech/wewumbo.io_v4/news/convenience-is-the-new-luxury-with-home-services/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-convenience-is-the-new-luxury-with-home-services-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-industry-talks-building-tomorrow-today-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/codebuild/output/src4072705058/src/github.com/WumboTech/wewumbo.io_v4/news/industry-talks-building-tomorrow-today/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-industry-talks-building-tomorrow-today-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-our-first-live-building-in-box-hill-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/codebuild/output/src4072705058/src/github.com/WumboTech/wewumbo.io_v4/news/our-first-live-building-in-box-hill/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-our-first-live-building-in-box-hill-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-place-btr-luncheon-qt-melbourne-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/codebuild/output/src4072705058/src/github.com/WumboTech/wewumbo.io_v4/news/place-btr-luncheon-qt-melbourne/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-place-btr-luncheon-qt-melbourne-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-smart-home-solutions-at-remi-resident-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/codebuild/output/src4072705058/src/github.com/WumboTech/wewumbo.io_v4/news/smart-home-solutions-at-remi-resident/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-smart-home-solutions-at-remi-resident-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-upper-west-sides-quiz-night-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/codebuild/output/src4072705058/src/github.com/WumboTech/wewumbo.io_v4/news/upper-west-sides-quiz-night/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-upper-west-sides-quiz-night-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-we-never-stop-bring-on-2023-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/codebuild/output/src4072705058/src/github.com/WumboTech/wewumbo.io_v4/news/we-never-stop-bring-on-2023/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-we-never-stop-bring-on-2023-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-we-wumbo-now-in-new-zealand-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/codebuild/output/src4072705058/src/github.com/WumboTech/wewumbo.io_v4/news/we-wumbo-now-in-new-zealand/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-we-wumbo-now-in-new-zealand-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-west-side-place-stage-2-now-settling-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/codebuild/output/src4072705058/src/github.com/WumboTech/wewumbo.io_v4/news/west-side-place-stage-2-now-settling/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-west-side-place-stage-2-now-settling-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-wewumbo-gets-digital-with-effi-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/codebuild/output/src4072705058/src/github.com/WumboTech/wewumbo.io_v4/news/wewumbo-gets-digital-with-effi/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-wewumbo-gets-digital-with-effi-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-wumbo-ai-coming-in-2024-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/codebuild/output/src4072705058/src/github.com/WumboTech/wewumbo.io_v4/news/wumbo-ai-coming-in-2024/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-wumbo-ai-coming-in-2024-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-wumbo-rewards-is-now-live-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/codebuild/output/src4072705058/src/github.com/WumboTech/wewumbo.io_v4/news/wumbo-rewards-is-now-live/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-wumbo-rewards-is-now-live-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-wumbolytics-super-tower-insights-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.js?__contentFilePath=/codebuild/output/src4072705058/src/github.com/WumboTech/wewumbo.io_v4/news/wumbolytics-super-tower-insights/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-js-content-file-path-news-wumbolytics-super-tower-insights-index-mdx" */),
  "component---src-pages-our-story-index-js": () => import("./../../../src/pages/our-story/index.js" /* webpackChunkName: "component---src-pages-our-story-index-js" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-resident-registration-index-js": () => import("./../../../src/pages/resident-registration/index.js" /* webpackChunkName: "component---src-pages-resident-registration-index-js" */),
  "component---src-pages-reward-index-js": () => import("./../../../src/pages/reward/index.js" /* webpackChunkName: "component---src-pages-reward-index-js" */),
  "component---src-pages-smart-building-index-js": () => import("./../../../src/pages/smart-building/index.js" /* webpackChunkName: "component---src-pages-smart-building-index-js" */),
  "component---src-pages-terms-index-js": () => import("./../../../src/pages/terms/index.js" /* webpackChunkName: "component---src-pages-terms-index-js" */),
  "component---src-pages-ubiquity-lab-index-js": () => import("./../../../src/pages/ubiquity-lab/index.js" /* webpackChunkName: "component---src-pages-ubiquity-lab-index-js" */),
  "component---src-pages-we-are-posi-index-js": () => import("./../../../src/pages/we-are-posi/index.js" /* webpackChunkName: "component---src-pages-we-are-posi-index-js" */),
  "component---src-pages-wumbo-store-index-js": () => import("./../../../src/pages/wumbo-store/index.js" /* webpackChunkName: "component---src-pages-wumbo-store-index-js" */),
  "component---src-pages-wumbolytics-index-js": () => import("./../../../src/pages/wumbolytics/index.js" /* webpackChunkName: "component---src-pages-wumbolytics-index-js" */)
}

